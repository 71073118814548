import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { StyledSlider, StyledSliderWrapper } from "components/carousel/newslider/styled";
import { FullWidthContainer } from 'components/grid/styled';

function NewSlider({ component, length, category, isAircraftIndexPage, minHeight }) {
  const [ cardWidth, setCardWidth ] = useState(0);

  let categoryId;

  if (category) {
    categoryId = category.replace(/ /g, "-").toLowerCase()
  }
  
  const handleOnClick = (index) => {
    let cards;

    if (isAircraftIndexPage) {
      cards = document.querySelectorAll(`#${categoryId} .aircraft-card`)[index]
    } else {
      cards = document.querySelectorAll(`#${categoryId} .aircraft-card`)[index] || document.querySelectorAll(`#${categoryId} .carousel-card`)[index]
    }

    if (cards) {
      const position = cards.getBoundingClientRect();

      setCardWidth(oldPosition => {
        return ((position.x + oldPosition) - 25)
      })
    }    
  }

  return (
    <FullWidthContainer overflowX="hidden">
      <StyledSliderWrapper 
        cardWidth={cardWidth} 
        minHeight={minHeight}
        listLength={length.length}
      >
        <StyledSlider cardWidth={cardWidth} id={categoryId}>
          {component}
        </StyledSlider>
        <section>
          {length.slice(0, 8).map((el, index) => {
          return (
            <div
              onClick={() => handleOnClick(index)}
            />
          )
        })}
        </section>
      
      </StyledSliderWrapper>
    </FullWidthContainer>
    
  )
}

NewSlider.propTypes = {
  category: PropTypes.string,
  component: PropTypes.object,
  isAircraftIndexPage: PropTypes.bool,
  length: PropTypes.array,
  minHeight: PropTypes.number,
}

export default NewSlider


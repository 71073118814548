import styled from "styled-components";

export const StyledSlider = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 1200px;
  transform: translateX(${
    props => props.cardWidth ? `-${props.cardWidth}px` : "0"
  });
  transition: transform 700ms ease-out;
`

export const StyledSliderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  min-height: ${props => props.minHeight ? props.minHeight : "400px"};

  @media (min-width: 640px) {
    display: none;
  }

  section {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 40px; */

    div {
      color: white;
      cursor: pointer;
      width: ${props => props.listLength > 8 ? "14px" : "15px"};
      height: ${props => props.listLength > 8 ? "14px" : "15px"};
      border-radius: 50%;
      background-color: #2C1A30;
      margin-right: ${props => props.listLength > 8 ? "18px" : "40px"};

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`